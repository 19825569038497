import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { GymClientMembershipsSearchCriteria } from '../../models/search-criterias/gym-client-memberships-searchcriteria';
import { GymClientMembershipsSearchResult } from '../../models/search-result/gym-client-memberships-search-result';
import { GetClientMembershipInfoModel } from '../../models/search-criterias/client-membership-info-model';
import { ClientMembershipInfoDTO } from '../../models/classes/gym/client-membership-info-dto';

@Injectable({
  providedIn: 'root'
})

export class ClientGymMembershipService extends BaseService {
  isAlterEgoTaken(alterEgo: string): Observable<boolean> {
    const isTaken = false; //alterEgo.includes(alterEgo);
    return of(isTaken).pipe(delay(400));
  }
  private clientGymMembershipUrl // = super.BaseUrl() + 'Client';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService:SharedService
  ) {
    super();
    this.clientGymMembershipUrl = this.sharedService.ApiUrl  + 'GymClientMembership';
  }
  getApiUrl(){
    return this._BaseService.ApiUrl  ;
  }


  GetGymClientMemberships(clientGymMembershipsSearchCriteria: GymClientMembershipsSearchCriteria): Observable<GymClientMembershipsSearchResult> {

    return this.http.post<GymClientMembershipsSearchResult>(this.clientGymMembershipUrl + '/Get', clientGymMembershipsSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Client Gym Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<GymClientMembershipsSearchResult>('Fetched Client Gym Memberships id=${id}'))
      );
  }

  GetClientMembershipInfo(model: GetClientMembershipInfoModel): Observable<ClientMembershipInfoDTO> {

    return this.http.post<ClientMembershipInfoDTO>(this.clientGymMembershipUrl + '/GetClientMembershipInfo', model, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Client Gym Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ClientMembershipInfoDTO>('Fetched Client Gym Memberships id=${id}'))
      );
  }


}
