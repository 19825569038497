<div id="printClientBalanceDetails">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-info-circle"></i> &nbsp; {{'ClientBalanceInfo' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-lg-12" *ngIf="ClientBalance ">
        <div class="text-start py-4">

          <div class="row">
            <div class="col-lg-12">
              <div class="formCard">
                <table class="table table-sm">

                  <tr>
                    <td>{{ 'ClientName' | translate }}</td>
                    <td>{{ ClientBalance.ClientGlobalName }}</td>

                  </tr>

                  <tr>
                    <td>{{ 'ClientMobile' | translate }}</td>
                    <td>{{ ClientBalance.ClientMobile }}</td>

                  </tr>

                  <tr>
                    <td>{{ 'BalanceLastUpdateDate' | translate }}</td>
                    <td>{{ ClientBalance.BalanceLastUpdate | date: 'yyyy-MM-dd' }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'BalanceLastUpdateTime' | translate }}</td>
                    <td>{{ ClientBalance.BalanceLastUpdate | date: 'hh:mm a' }}</td>

                  </tr>


                  <tr>
                    <td>{{ 'TotalClientBalance-ForHim' | translate }}</td>

                    <td class="text-success" *ngIf="ClientBalance.TotalClientBalanceForHim > 0 ">
                      {{ ClientBalance.TotalClientBalanceForHim.toFixed(sharedService.GetFractionRoundCount) }}

                      <span>
                        &nbsp;
                        {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                        sharedService.CurrencyNameEn}}</span>
                    </td>
                    <td class="text-info" *ngIf="ClientBalance.TotalClientBalanceForHim == 0 ">
                      {{ ClientBalance.TotalClientBalanceForHim.toFixed(sharedService.GetFractionRoundCount) }}
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'TotalClientBalance-OnHim' | translate }}</td>

                    <td class="text-danger" *ngIf="ClientBalance.TotalClientBalanceOnHim > 0 ">
                      {{ ClientBalance.TotalClientBalanceOnHim.toFixed(sharedService.GetFractionRoundCount) }} <span>
                        &nbsp;
                        {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                        sharedService.CurrencyNameEn}}</span>
                    </td>
                    <td class="text-info" *ngIf="ClientBalance.TotalClientBalanceOnHim == 0 ">
                      {{ ClientBalance.TotalClientBalanceOnHim.toFixed(sharedService.GetFractionRoundCount) }}
                    </td>

                  </tr>

                  <tr>
                    <td>{{ 'ClientStatus' | translate }}</td>

                    <td *ngIf="ClientBalance.ClientBalanceStatus ==
                  clientBalanceStatus.Neutral" class="text-info">{{
                      ('Neutral'| translate) }}</td>
                    <td *ngIf="ClientBalance.ClientBalanceStatus ==
                  clientBalanceStatus.Creditor" class="text-success">{{
                      ('Creditor-ForHim' |translate)}}</td>
                    <td *ngIf="ClientBalance.ClientBalanceStatus ==
                    clientBalanceStatus.Debtor" class="text-danger">{{
                      ('Debtor-OnHim' |translate) }}</td>
                    <td *ngIf="ClientBalance.ClientBalanceStatus !=
                    clientBalanceStatus.Neutral &&
                    ClientBalance.ClientBalanceStatus !=
                    clientBalanceStatus.Creditor &&
                    ClientBalance.ClientBalanceStatus !=
                    clientBalanceStatus.Debtor" class="text-warning">{{
                      ('NotDetermined' |translate)}}
                    </td>
                  </tr>

                </table>


              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="col-md-12">
        <div class="filter-card">
          <div class="row px-2">
            <div class="col-md-4 col-sm-8 col-12 px-2">
              <app-search (SendFreeText)="ReciveFreeText($event)" [SearchName]="('SearchByBillCode')">
              </app-search>
            </div>


            <div class="col-md-5 col-sm-4  px-2">
              <div class="input-group">
                <select class="form-control" [(ngModel)]="ClientBalanceTransactionType"
                  (change)="filterByClientBalanceTransactionType($event)">
                  <option value="-1" selected> {{ 'TransactionType' | translate }}</option>
                  <option [value]="clientBalanceTransactionType.AddToClientBalance">
                    {{'AddToClientBalance'|translate}} </option>
                  <option [value]="clientBalanceTransactionType.WithdrawBalanceToTheClient">
                    {{'WithdrawBalanceToTheClient'|translate}}</option>
                  <option [value]="clientBalanceTransactionType.PayForPostpaidTransaction">
                    {{'PayForPostpaidTransaction'|translate}}</option>
                  <option [value]="clientBalanceTransactionType.PayForPostpaidTransactionFromCashSale">
                    {{'PayForPostpaidTransactionFromCashSale'|translate}}</option>
                  <option [value]="clientBalanceTransactionType.RemainingFromPostpaidTransaction">
                    {{'RemainingFromPostpaidTransaction'|translate}}</option>
                  <option [value]="clientBalanceTransactionType.UseClientBalanceInBill">
                    {{'UseClientBalanceInBill'|translate}}</option>
                  <option [value]="clientBalanceTransactionType.AddToClientBalanceFromBill">
                    {{'AddToClientBalanceFromBill'|translate}}</option>
                  <option [value]="clientBalanceTransactionType.AddToClientBalanceFromBillRefund">
                    {{'AddToClientBalanceFromBillRefund'|translate}}</option>
                </select>
              </div>
            </div>


            <div class="col-md-3 col-sm-4  px-2">
              <div class="input-group">
                <select class="form-control" [(ngModel)]="ClientBalanceTransactionDirection"
                  (change)="filterByClientBalanceTransactionDirection($event)">
                  <option value="-1" selected> {{ 'TransactionDirection' | translate }}</option>
                  <option [value]="clientBalanceTransactionDirection.Input">{{'Adding'|translate}}</option>
                  <option [value]="clientBalanceTransactionDirection.Output">{{'Deducting'|translate}}</option>
                </select>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="(!Loading && ClientBalanceDetails); let ClientBalanceDetails; else loading">
        <div class="table-card">
          <table class="table table-stripedd table-sm table-bordered">
            <thead class="table-head">
              <tr style="color: black; font-size: large;">

                <th style="width: 5%;" class="formatTd text-center">{{ 'DoneInBranch' | translate }}</th>

                <th style="width: 5%;" class="formatTd text-center">{{ 'TransactionType' | translate }}</th>

                <th style="width: 2%;" class="formatTd text-center">{{ 'BillCode' | translate }}</th>

                <th style="width: 2%;" class="formatTd text-center">{{ 'TransactionDate' | translate }}</th>

                <th style="width: 2%;" class="formatTd text-center">{{ 'TransactionTime' | translate }}</th>

                <th style="width: 3%;" class="formatTd text-center">{{ 'TransactionTotal' | translate }}</th>

                <th style="width: 2%;" class="formatTd text-center">{{ 'TransactionDirection' | translate }}</th>

                <th style="width: 7%;" class="formatTd text-center">{{ 'ClientBalanceAfterTransaction' | translate }}
                </th>

              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let clientBalanceDetail of ClientBalanceDetails; let i = index"
                [ngClass]="clientBalanceDetail.ClientBalanceTransactionDirection ==
              clientBalanceTransactionDirection.Input ? 'greenColor formatTd  text-center' : 'redColor formatTd  text-center'">




                <td class="formatTd">{{ clientBalanceDetail.DoneInBranchName | translate}}</td>

                <td class="formatTd">{{ clientBalanceDetail.ClientBalanceTransactionTypeText | translate}}</td>

                <td class="formatTd">{{
                  (clientBalanceDetail.ClientBalanceTransactionType ==
                  clientBalanceTransactionType.RemainingFromPostpaidTransaction ||

                  clientBalanceDetail.ClientBalanceTransactionType ==
                  clientBalanceTransactionType.PayForPostpaidTransaction ||

                  clientBalanceDetail.ClientBalanceTransactionType ==
                  clientBalanceTransactionType.PayForPostpaidTransactionFromCashSale ||

                  clientBalanceDetail.ClientBalanceTransactionType ==
                  clientBalanceTransactionType.UseClientBalanceInBill ||

                  clientBalanceDetail.ClientBalanceTransactionType ==
                  clientBalanceTransactionType.AddToClientBalanceFromBill ||

                  clientBalanceDetail.ClientBalanceTransactionType ==
                  clientBalanceTransactionType.AddToClientBalanceFromBillRefund ?

                  (clientBalanceDetail.TransactionCode) : "-" )}}</td>

                <td class="formatTd">{{ clientBalanceDetail.AddedDate | date: 'yyyy-MM-dd' }}</td>

                <td class="formatTd">{{ clientBalanceDetail.AddedDate | date: 'hh:mm a' }}</td>

                <td class="formatTd">{{ clientBalanceDetail.TotalAmount }}
                  <span>
                    &nbsp;
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                    sharedService.CurrencyNameEn}}</span>
                </td>


                <td class="formatTd" *ngIf="clientBalanceDetail.ClientBalanceTransactionDirection ==
                clientBalanceTransactionDirection.Input">{{
                  ('Adding'| translate) }}</td>
                <td class="formatTd" *ngIf="clientBalanceDetail.ClientBalanceTransactionDirection ==
                clientBalanceTransactionDirection.Output">{{
                  ('Deducting' |translate)}}</td>


                <td class="formatTd" *ngIf="clientBalanceDetail.ClientBalanceTransactionDirection !=
                clientBalanceTransactionDirection.Input &&
            clientBalanceDetail.ClientBalanceTransactionDirection !=
            clientBalanceTransactionDirection.Output ">{{
                  ('NotDetermined' |translate)}}
                </td>

                <td class="formatTd">{{ clientBalanceDetail.ClientBalanceAfterTransaction }}
                  <span>
                    &nbsp;
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                    sharedService.CurrencyNameEn}}</span>
                </td>

              </tr>


            </tbody>
          </table>
          <div class="text-center py-4 my-4" *ngIf="ClientBalanceDetails.length == 0">
            <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
          </div>
        </div>
      </div>

      <div class="col-md-12" [hidden]="!ClientBalanceDetails">
        <app-pagination (GetSources)="GetClientBalanceDetails()" #pager></app-pagination>
      </div>

      <div class="col-md-12">
        <ng-template #loading>
          <div class="col-md-12">
            <app-loading></app-loading>
          </div>
        </ng-template>
      </div>

      <div class="col-lg-12" *ngIf="false">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
