import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/other/shared.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { BookingOrTransactionItemType } from 'src/app/models/enums/booking-or-transaction-item-type';
import * as _ from 'underscore';

@Component({
  selector: 'app-print-employee-booking',
  templateUrl: './print-employee-booking.component.html',
  styleUrls: ['./print-employee-booking.component.scss']
})
export class PrintEmployeeBookingComponent implements OnInit {


  public item;

  @Input('Booking')
  set in(booking) {

    this.item = booking;
    this.handleBookingServices();

  }

  @Input() public readonly AccountSetup;
  @Input() public dirc;
  public BillInfoUrl: string;
  public printLang: string = 'ar';

  constructor(
    public sharedService: SharedService,
    public translate: TranslateService,
    public translateService: TranslateService

  ) {

  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }

  ngOnInit(): void {


    if (this.item) {
      this.handleBookingServices();
    }
    this.BillInfoUrl = location.origin + "/transaction/bill-info/billItemId/";

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintEmployeeLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = "en";
      this.sharedService.SetTranslationPipeData();
    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = "ar";
      this.sharedService.SetTranslationPipeData();

    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'all') {
      console.log('all')

    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage && this.AccountSetup?.SelectedPrintEmployeeLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintEmployeeLanguage;
    }

  }

  handleBookingServices() {
    this.item.BookingEmployees = [];

    if (this.item.BookingServices && this.item.BookingServices.length > 0) {

      this.item.BookingServices.forEach((bookingService, index) => {

        if (bookingService.EmployeeId > 0) {
          this.item.BookingEmployees.push(bookingService.EmployeeId);
        }
        if (!bookingService.Quantity) {
          bookingService.Quantity = 1;
        }

        if (bookingService.ItemType == BookingOrTransactionItemType.Package) {

          var getPackageOriginalPrice = this.item.BookingServices.
            filter(bs => bs.ItemType == BookingOrTransactionItemType.Package &&
              bs.OffersSettingId == bookingService.OffersSettingId)?.map(a => a.Service.Price)?.
            reduce(function (a, b) {
              return a + b;
            });

          var getPackagePrice = this.item.ClientPackages.
            find(cp => cp.OffersSettingId == bookingService.OffersSettingId)?.TotalNetPrice ?? 0;

          bookingService.Price = bookingService?.Service?.Price ?? 0;
          bookingService.TotalNetPrice = ((bookingService?.Service?.Price ?? 0) /
            getPackageOriginalPrice) * getPackagePrice;

        }


      });
      this.item.BookingEmployees = _.uniq(this.item.BookingEmployees);
    }
  }

  hasNonNullEmployeeTransactionCommission(item: any): boolean {
    return item.BookingServices.some(service => service.EmployeeTransactionCommission
      && service.EmployeeTransactionCommission.CommissionValue > 0);
  }

  CheckIfPackage(BookingServices: any): boolean {
    return BookingServices && BookingServices.length > 0 &&
      BookingServices.some(b => b.TransactionsPackageServices &&
        b.TransactionsPackageServices.length > 0);
  }

  getEmployeeName(employeeId: number): string {

    var getBookingService = this.item.BookingServices.find(bs => bs.EmployeeId == employeeId);

    if (getBookingService) {

      var employeeName = getBookingService.Employee ? (this.printLang == "ar" ?
        getBookingService.Employee?.NameAr :
        getBookingService.Employee?.NameEn) :
        this.translateService.instant('NotFound');

      return employeeName;
    }

    return null;

  }

}
