
export enum TransactionType {

    CashSale = 1,
    Exchange = 3,
    BookingPayment = 4,
    OpenBalance = 5,
    PettyCash = 6,
    CashierTransfere = 7,
    RecieveFromCashier = 8,
    HandOver = 9,
    ClientDownPayment = 11,
    WalletRefund = 13,
    AllCancellations = 15,
    ChargeBeginningShift = 16,
    BookingDownPaymentRefund = 12,
    PettyCashRefund = 10,
    SaleRefund = 2,
    Purchase = 17,
    BondOut = 18,
    Journal = 19,
    StockTransfer = 20,
    StockWaste = 21,
    StockAdjustment = 22,
    PurchaseRefund = 23,
    Commission = 24,
    VoucherIn = 25,
    Revenue = 27,
    RevenueRefund = 28,
    CashSettlement = 29,
    AddToClientBalance = 30,
    WithdrawBalanceToTheClient = 31,
    AddToAnyClientBalance= 32

}
