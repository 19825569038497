<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-user px-2"></i> {{'Print' | translate}}
    </h5>

  </div>
    <div *ngIf="item">
      <div id="print-content" class="printBox" style="background-color: #fff;">
        <div style="
        text-align: start;
        direction: rtl;
        width: 100%;
        margin: 0;
        background-color: #fff;
      " [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
          <table style="width: 100%;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
            <tr class="row">
              <td class="col-3 text-start">
                <p style="font-family:initial; font-size: 18px; margin: 0;font-weight: 600; color: #000;">
                  {{ item.Date | date:'dd/MM/yy'}}</p>
                <p style="font-family:initial; font-size: 18px; margin: 0;font-weight: 600; color: #000;">
                  {{ item.Date | date:'HH:mm'}}</p>
              </td>
              <td class="col-6 text-center">
                <div style="text-align: center;" *ngIf="AccountSetup?.ImagePath">
                  <img [src]="AccountSetup?.ImagePath" style="width: 60px;">
                </div>
                <p class="hed" style="margin: 5px 0  0 0; padding: 0; text-align: center; color: #000;
                font-weight: 600; font-size: 16px;"> {{ sharedService.BranchNameAr }}
                </p>
              </td>
              <td class="" style="text-align: end;">
                <p style="font-family:initial; font-size: 18px; margin: 0;font-weight: 600; color: #000;">{{
                  item.Id}}</p>
                <!-- <p style="font-family:initial; font-size: 18px; margin: 0;font-weight: 600; color: #000;">{{
                AccountSetup.TaxRegistrationNumber }} </p> -->
              </td>
            </tr>
          </table>
          <!-- <p style="margin: 0; padding: 0; text-align: center; color: #000;
           font-weight: 700; font-size: 20px;font-weight: 500;"> {{ sharedService.BranchNameEn }} </p> -->
          <div>
            <hr>
            <table style="width: 100%; text-align: start;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
              <tr>
                <td style="font-family:initial; font-size: 20px;font-weight: 500; width: 160px; color: #000;">
                  <p style="font-size:16px; font-weight:600; margin:0px;">{{'Client' | translate }} : {{
                    item.Client?.NameAr }} </p>

                  <p style="font-size:16px; font-weight:600; margin:0px;">
                    {{ 'UserName' | translate }} :
                    {{item.AddUser?.UserName }}

                  </p>
                  <p>
                    <span *ngIf="item.BookingPlace == 1">{{'InHome' | translate}}</span>
                  </p>
                </td>
                <td style="text-align: end; width: 150px;">
                  <div style="text-align: end;" *ngIf="AccountSetup?.QRImagePath">
                    <img [src]="AccountSetup?.QRImagePath" style="width: 120px;">
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div>

            <div *ngIf="item.BookingServices?.length > 0">
              <table border="1" style="width: 100%; border-collapse: collapse; text-align: start !important; margin: 15px 0;
                border: 2px solid #000;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
                <thead style="text-align: start !important; " [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
                  <tr style="text-align: start !important; " [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
                    <th
                      style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Name' |
                      translate }}</th>
                    <th
                      style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Employee' |
                      translate }}</th>
                    <th
                      style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Quantity' |
                      translate }}</th>
                    <th
                      style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #000; padding:5px;">
                      {{ 'Price' | translate
                      }}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let bookingService of item.BookingServices; let i=index">
                  <tr style="text-align: start;">
                    <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                      {{ bookingService.Service.NameAr }}

                      <!-- <span *ngFor="let room of bookingService.ResourceManagments; let i=index"> - {{ room.ResourceName}}
                  </span>
                   -->
                      <!-- <span *ngFor="let room of bookingService.ResourceManagments; let i=index"> - {{ room.ResourceName}}
                  </span> -->
                    </td>
                    <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                      - {{ sharedService.Lang == 'ar' ?
                      bookingService.Employee?.NameAr :
                      bookingService.Employee?.NameEn}}
                      <br>
                      <!-- <span *ngIf="bookingService.SecondEmployee">
                    - {{ sharedService.Lang == 'ar' ? bookingService.SecondEmployee?.NameAr :
                    bookingService.SecondEmployee?.NameEn}}
                  </span> -->
                    </td>
                    <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                      {{bookingService.Quantity }}
                    </td>
                    <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                      {{bookingService.TotalNetPrice.toFixed(sharedService.GetFractionRoundCount) }}
                    </td>
                  </tr>
                  <tr *ngIf="AccountSetup?.AllowUseRooms == true">
                    <td colspan="4" *ngFor="let clientservice of item.ClientServices; let i=index">
                      <span *ngFor="let room of clientservice.ResourceManagements">
                        <span *ngIf="room.ResourceId">
                          {{room.ResourceName}}, &nbsp;&nbsp;&nbsp;
                          {{ 'From' | translate }}: {{room.StartTime | date:'shortTime' }} , &nbsp;
                          {{ 'To' | translate }}: {{room.EndTime | date:'shortTime'}} ,
                        </span>
                      </span>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <table *ngIf=item.Comment>
              <tr>
                <td>
                  {{item.Comment}}
                </td>
              </tr>
            </table>
            <hr>
            <div>

              <table style="width: 100%; text-align: start; ">

                <tr>
                  <td style="font-family:initial;font-size: 20px;font-weight: 500; color: #000; width: 160px">
                    {{ 'Total' |
                    translate }} </td>
                  <td style="font-family:initial;font-size: 20px;font-weight: 500; color: #000;">
                    <span style="display: inline-block; width:60px;">{{
                      item.TotalNetPrice.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="display: inline-block; width:60px;"> {{sharedService.Lang== 'ar' ?
                      sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}</span>
                  </td>
                </tr>

                <tr>
                  <td style="font-family:initial;font-size: 20px;font-weight: 500; color: #000; width: 160px">
                    {{ 'Payed' |
                    translate }} </td>
                  <td style="font-family:initial;font-size: 20px;font-weight: 500; color: #000;">
                    <span style="display: inline-block; width:60px;">{{
                      item.PaidAmount.toFixed(sharedService.GetFractionRoundCount)}}</span>
                    <span style="display: inline-block; width:60px;"> {{sharedService.Lang== 'ar' ?
                      sharedService.CurrencyNameAr
                      : sharedService.CurrencyNameEn}}</span>
                  </td>
                </tr>

              </table>
            </div>
            <div style="width: 100%; height: 2px; border:1px solid #000; margin: 5px;"></div>
            <p
              style="text-align: center; font-weight: 500;color: #000; margin: 10px 0px;font-family:initial; font-size: 20px;font-weight: 500;">
              {{ 'Phone' |
              translate }} : {{AccountSetup?.PhoneNumber}} -
              {{AccountSetup?.MobileNumber}}</p>
            <p
              style="text-align: center; font-weight: 500;color: #000; margin: 10px 0px;font-family:initial; font-size: 20px;font-weight: 500;">
              {{ 'TaxRegistrationNumber' | translate }}: {{ AccountSetup?.TaxRegistrationNumber }}
            </p>
            <p
              style="text-align: center; font-weight: 500;color: #000;margin: 10px 0px;font-family:initial; font-size: 20px;font-weight: 500;">
              {{ 'Address' |
              translate }} : {{AccountSetup?.Address}}</p>
            <div style="text-align: center;" *ngIf="AccountSetup?.QRImagePath">
              <img [src]="AccountSetup?.QRImagePath" style="width: 120px;">
            </div>

            <div style="width: 100%; height: 1px; border:1px solid #000; margin: 5px;"></div>
            <p
              style="text-align: center; font-weight: 500;color: #000;font-family:initial; font-size: 20px;font-weight: 500;">
              {{AccountSetup?.PrintReceiptAdvertText}} </p>
          </div>
        </div>
      </div>
    </div>

  </div>

