import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintBillA4Component } from './shared/print-component/print-contract/print-contract.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DeleteStepsComponent } from './delete-steps/delete-steps.component';

const routes: Routes = [
  // { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  //{ path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    loadChildren: () =>
      import('./auth-new/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'details',
    loadChildren: () =>
      import('./auth-new/business-details/business-details.module').then(
        (m) => m.BusinessDetailsModule
      ),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./calendar/calendar.module').then((m) => m.CalendarModule),
  },
  { path: 'print/:id', loadChildren: () => import('./print-mobile/print-mobile.module').then(m => m.PrintMobileModule) },
  { path: 'invoices/:id', loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule) },
  { path: 'print-contract', component: PrintBillA4Component },
  { path: 'registration-cycle', loadChildren: () => import('./registration-cycle/registration-cycle.module').then(m => m.RegistrationCycleModule), data: { title: "PhaseOne|RegistrationInGlameraBusiness" }, runGuardsAndResolvers: "always" },
  { path: 'pure', loadChildren: () => import('./pure/pure.module').then(m => m.PureModule), data: { title: "Pure" }, runGuardsAndResolvers: "always" },
  { path: 'noluci', loadChildren: () => import('./noluci/noluci.module').then(m => m.NoluciModule), data: { title: "Noluci" }, runGuardsAndResolvers: "always" },
  { path: 'padioi', loadChildren: () => import('./padioi/padioi.module').then(m => m.PadioiModule), data: { title: "Padioi" }, runGuardsAndResolvers: "always" },
  { path: 'file-and-style', loadChildren: () => import('./file-and-style/file-and-style-routing.module').then(m => m.FileAndStyleRoutingModule), data: { title: "File and style" }, runGuardsAndResolvers: "always" },
  { path: 'soft-spa', loadChildren: () => import('./soft-spa/soft-spa.module').then(m => m.SoftSpaModule), data: { title: "Soft Spa" }, runGuardsAndResolvers: "always" },
  { path: 'ghadaf', loadChildren: () => import('./ghadaf/ghadaf.module').then(m => m.GhadafModule), data: { title: "Ghadaf" }, runGuardsAndResolvers: "always" },
  { path: 'bt', loadChildren: () => import('./bt/bt.module').then(m => m.BtModule), data: { title: "BT" }, runGuardsAndResolvers: "always" },
  { path: 'oriental-relax', loadChildren: () => import('./oriental-relax/oriental-relax.module').then(m => m.OrientalRelaxModule), data: { title: "BT" }, runGuardsAndResolvers: "always" },
  { path: 'gentel-man', loadChildren: () => import('./gentel-man/gentel-man.module').then(m => m.GentelManModule), data: { title:"Gentel Man" }, runGuardsAndResolvers: "always" },
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'delete-steps', component: DeleteStepsComponent},
  { path: '**', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },



  // { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
