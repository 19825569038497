import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateLang'
})
export class DateLangPipe implements PipeTransform {


  constructor(public translateService: TranslateService,) {

  }

  transform(value: Date | any, ...args: any[]): any {

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    value = new Date(value);

    if (this.translateService.currentLang == 'ar') {
      return value.toLocaleTimeString('ar-EG') + '  -  ' + value.toLocaleDateString('ar-EG', options);
    }
    else {
      return value.toLocaleTimeString('en-US') + '  -  ' + value.toLocaleDateString('en-US', options);
    }

  }

}
