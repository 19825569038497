import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { BookingOrTransactionItemType } from '../../../models/enums/booking-or-transaction-item-type';
import { TransactionType } from '../../../models/enums/transaction-type';
import { SharedService } from '../../../services/other/shared.service';
import { PaymentMethodType } from 'src/app/models/enums/payment-method-type';
import { TypeThatClientsQueueSystemDependsOn } from '../../../models/enums/type_that_clients_queue_system_depends_on';
import { AccountSetup } from '../../../models/classes/account-setup/account-setup';
import { EmployeeShowType } from 'src/app/models/enums/employee-show-type';

@Component({
  selector: 'app-print-bill-and-items',
  templateUrl: './print-bill-and-items.component.html',
  styleUrls: ['./print-bill-and-items.component.scss'],
})
export class PrintBillAndItemsComponent implements OnInit {

  public item;

  @Input('item')
  set in(item) {

    this.item = item;

    if (item?.TrProductServices && item?.TrProductServices?.length > 0) {

      this.handleTransItems();

    }

  }

  @Input() public readonly AccountSetup: AccountSetup;
  @Input() public dirc;
  public BillInfoUrl: string;
  public BillInfoForPackageUrl: string;
  public printLang: string = 'ar';
  public
  cashsaleId: number;
  private htmlRoot = this.document.documentElement;

  bill;
  constructor(
    public sharedService: SharedService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) {
    // translate.addLangs(['en', 'ar']);
    // translate.setDefaultLang(localStorage.getItem('lang'));
    // this.htmlRoot.setAttribute('dir', localStorage.getItem('dir'));
    // this.translate.use(localStorage.getItem('lang'));
  }

  public get employeeShowType(): typeof EmployeeShowType {
    return EmployeeShowType;
  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  // value = 'https://app.glamera.com/invoices/qr-code.pdf/';
  value = '';

  ngOnInit(): void {


    var GGGG = this.item.TransEmployees;

    this.BillInfoUrl = location.origin + "/transaction/bill-info/billItemId/";
    this.BillInfoForPackageUrl = location.origin + "/transaction/bill-info/billPackageItemId/";

    this.cashsaleId = this.route.snapshot.params['id'];

    this.value =
      'ARRCZWF1dHkgVG91Y2ggLSBjYWlybwIJOTg0OTg0NTQ2AxQyMDIxLTEyLTE0VDExOjAzOjIzWgQGMjEwLjAwBQUyMS4wMA==';

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintInvoiceLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = "en";
      this.sharedService.SetTranslationPipeData();
    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = "ar";
      this.sharedService.SetTranslationPipeData();

    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'all') {
      console.log('all')

    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintInvoiceLanguage && this.AccountSetup?.SelectedPrintInvoiceLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintInvoiceLanguage;
    }
  }
  PrintPage() {
    window.print();
  }

  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  public get typeThatClientsQueueSystemDependsOn(): typeof TypeThatClientsQueueSystemDependsOn {
    return TypeThatClientsQueueSystemDependsOn;
  }

  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }
  public get paymentMethodType(): typeof PaymentMethodType {
    return PaymentMethodType;
  }
  hasNonNullEmployeeTransactionCommission(item: any): boolean {
    return item.TrProductServices.some(service => service.EmployeeTransactionCommission
      && service.EmployeeTransactionCommission.CommissionValue > 0);
  }


  handleTransItems() {


    this.item.TransactionItems = [];

    this.item.TrProductServices.forEach((trProductService, index) => {

      if (trProductService.ServiceId > 0) {
        trProductService.ItemId = trProductService.ServiceId;
      } else if (trProductService.PackageId > 0) {
        trProductService.ItemId = trProductService.PackageId;
      } else if (trProductService.GiftCardId > 0) {
        trProductService.ItemId = trProductService.GiftCardId;
      } else if (trProductService.ProductId > 0) {
        trProductService.ItemId = trProductService.ProductId;
      }

      this.item.TransactionItems.push(trProductService);

      if (trProductService.TransactionsPackageServices &&
        trProductService.TransactionsPackageServices.length > 0) {

        trProductService.TransactionsPackageServices.forEach((transactionPackageServices, index) => {

          if (transactionPackageServices.ServiceId > 0) {

            transactionPackageServices.ItemType = BookingOrTransactionItemType.Service;
            transactionPackageServices.ItemId = transactionPackageServices.ServiceId;
            transactionPackageServices.Quantity = 1;
            transactionPackageServices.OriginalPrice = transactionPackageServices.ServicePriceInPackage ?? 0;
            transactionPackageServices.Total = transactionPackageServices.ServicePriceInPackage;
            transactionPackageServices.Note = "PackageService";
            transactionPackageServices.ItemGlobalName = this.sharedService.Lang == 'ar' ?
              transactionPackageServices.Service.NameAr : transactionPackageServices.Service.NameEn;
          }

          this.item.TransactionItems.push(transactionPackageServices);

        });
      }
    });

  }

  getItemName(transItem: any): string {

    var getTransactionItem = this.item.TransactionItems.
      find(ti => ti.ItemType == transItem.ItemType && ti.ItemId == transItem.ItemId);

    if (getTransactionItem) {

      var itemName = getTransactionItem.ItemGlobalName ? getTransactionItem.ItemGlobalName :
        this.translate.instant('NotFound');

      return itemName;
    }

    return this.translate.instant('NotFound');

  }
  getCategoryNameName(transItem: any): string {

    var getTransactionItem = this.item.TransactionItems.
      find(ti => ti.ItemType == transItem.ItemType && ti.ItemId == transItem.ItemId);

    if (getTransactionItem && (getTransactionItem?.service?.Category ||
      getTransactionItem?.product?.Category)) {

      var categoryName = getTransactionItem?.service?.Category?.GlobalName ??
        getTransactionItem?.product?.Category?.GlobalName;

      return categoryName;
    }

    return null;

  }
}
