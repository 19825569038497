<div class="row">
  <div class="col-md-12">
    <nav aria-label="breadcrumb">
      <div class="row">
        <div class="col-12">
          <ol class="breadcrumb">
            <button
              *ngIf="sharedService.CheckAuthorization(2900) && !asModal"
              class="btn add-new-btn"
              data-toggle="modal"
              data-target="#ModalAddEdit"
              (click)="ModelAdd()"
            >
              <i class="fas fa-plus"></i> &nbsp;
              {{ "CreateProductCategory" | translate }}
            </button>
            <li class="breadcrumb-item">
              <a href="#"> {{ "Stock" | translate }}</a>
            </li>
            <li class="breadcrumb-item active">
              {{ "ProductCategories" | translate }}
            </li>
            <button class="btn add-new-btn-two" (click)="downloadFile()">
              <i class="fa fa-download"></i> &nbsp; {{'DownloadTemplate' | translate }} </button>

          
            <input id="file-upload" #fileUpload type="file" accept=".xlsx, .xls" (change)="onFileSelected($event)" />

            <button class="btn add-new-btn-three" (click)="handleUploadFile();" type="button" [disabled]="Loading">
              <span *ngIf="!file"> <i class="fas fa-cloud-upload-alt"></i> &nbsp;
                {{ 'Upload' | translate }}</span>
              <span *ngIf="file && !Loading">
                <i class="fas fa-save"></i> &nbsp; {{'SaveFile' | translate
                }} &nbsp; ({{ FileName }})</span>
              <span *ngIf="file && Loading">{{ 'LoadingImportFile' | translate }}</span>
            </button>
          </ol>
          <!-- Modal add New -->
          <div
            class="modal fade"
            id="ModalAddEdit"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ "New" | translate }}
                  </h5>
                </div>
                <form [formGroup]="GForm" (ngSubmit)="CreateData()">
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="formCard">
                          <!-- <h6> {{ 'Details' | translate }}</h6>
                          <hr> -->
                          <div class="form-group">
                            <!-- <label>{{ 'NameAr' | translate }}</label> -->
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'NameAr' | translate }}"
                              formControlName="NameAr"
                              required
                              maxlength="50"
                            />
                          </div>
                          <div class="form-group">
                            <!-- <label>{{ 'NameEn' | translate }}</label> -->
                            <input
                              type="text"
                              class="form-control"
                              formControlName="NameEn"
                              required
                              maxlength="50"
                              placeholder="{{ 'NameEn' | translate }}"
                            />
                          </div>
                          <div class="form-group">
                            <!-- <label >{{ 'OnlineDescriptionAr' | translate }}</label> -->
                            <input
                              type="text"
                              class="form-control"
                              formControlName="OnlineDescription"
                              maxlength="50"
                              placeholder="{{
                                'OnlineDescriptionAr' | translate
                              }}"
                            />
                          </div>
                          <div class="form-group">
                            <!-- <label >{{ 'OnlineDescriptionEn' | translate }}</label> -->
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{
                                'OnlineDescriptionEn' | translate
                              }}"
                              formControlName="OnlineDescriptionEn"
                              maxlength="50"
                            />
                          </div>
                          <div class="form-group">
                            <!-- <label >{{ 'OnlineDetails' | translate }}</label> -->
                            <textarea
                              rows="5"
                              cols="50"
                              class="form-control"
                              placeholder="{{ 'OnlineDetails' | translate }}"
                              formControlName="OnlineDetails"
                            ></textarea>
                          </div>

                          <div class="form-inline">
                            <label>{{ "Status" | translate }} </label>
                            <div class="switch mx-3">
                              <label>
                                <input
                                  type="checkbox"
                                  formControlName="Status"
                                  name="Status"
                                  checked="checked"
                                />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="formCard">
                          <!-- <div class="form-group">
                            <label>{{ 'CompanyCategories' | translate }}</label>
                            <select class="form-control" formControlName="ComapnyCategoriesId">
                              <option value="" disabled>
                                {{ 'CompanyCategories' | translate }}</option>
                              <option *ngFor="let companyCategory of ComapnyCategoriess" [value]="companyCategory.Id">
                                {{companyCategory.NameEn}}
                              </option>
                            </select>
                          </div> -->
                          <!--  <div class=" form-group field">
                            <label class="ClassificationTitle" for="ClassId">{{ 'ClassId' | translate }}</label>
                            <select class="form-control" formControlName="ClassId">
                              <option value="" disabled>
                                {{ 'CompanyCategories' | translate }}</option>
                              <option *ngFor="let classification of Classifications" [value]="classification.dbId">
                                {{classification.na}}
                              </option>
                            </select>
                          </div> -->
                          <hr />
                          <h6>
                            {{ "Loyality" | translate }}
                          </h6>
                          <hr />
                          <div class="form-group" [hidden]="true">
                            <label>{{ "PointsRedemption" | translate }}</label>
                            <select
                              class="form-control"
                              formControlName="PointsRedemption"
                            >
                              <option value="1">
                                {{ "FreewhenClienthaspoints" | translate }}
                              </option>
                              <option value="0">
                                {{ "Nevergiveforfree" | translate }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group field">
                            <label for="LoyaltyPointSystem">{{
                              "LoyaltyPointSystem" | translate
                            }}</label>
                            <select
                              class="form-control"
                              formControlName="LoyaltyPointSystem"
                            >
                              <option
                                *ngFor="let loyaltysystem of LoyaltyPointSystem"
                                [value]="loyaltysystem.Id"
                              >
                                {{ loyaltysystem.TranslateKey | translate }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="form-group"
                            *ngIf="GForm.get('LoyaltyPointSystem').value == 1"
                          >
                            <label for="LoyaltyPoints">{{
                              "LoyaltyPoints" | translate
                            }}</label>
                            <input
                              type="number"
                              class="form-control"
                              formControlName="LoyaltyPoints"
                            />
                          </div>

                          <div class="form-inline">
                            <label>
                              {{
                                "IsFastCategoryForProducts" | translate
                              }}</label
                            >
                            <div class="switch mx-3">
                              <label>
                                <input
                                  type="checkbox"
                                  formControlName="IsFastCategory"
                                  name="IsFastCategory "
                                  checked="checked"
                                />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="col-md-12">
                        <div class="formCard">
                          <div class="form-group col-md-12">
                            <label
                              >{{ "BranchesToViewAndUse" | translate }}
                              <span class="text-info">
                                -
                                {{
                                  "Note-ThisWillAllowedInMainBranchIfChooseBranchesOrNot"
                                    | translate
                                }}
                              </span>
                            </label>
                            <ng-select
                              placeholder="{{
                                'BranchesToViewAndUse' | translate
                              }}"
                              [multiple]="true"
                              [hideSelected]="true"
                              [items]="CompanyAccountSetups"
                              bindLabel="GlobalName"
                              bindValue="Id"
                              name="CategoryBranchesIdList"
                              formControlName="CategoryBranchesIdList"
                              [(ngModel)]="CategoryBranchesIdList"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button class="btn btn-danger" data-dismiss="modal">
                      {{ "Close" | translate }}
                    </button>
                    <button class="btn btn-success" [disabled]="!GForm.valid">
                      {{ "Submit" | translate }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div class="col-md-12">
    <div class="filter-card">
      <div class="row px-2">
        <div class="col-md-4 col-sm-8 px-2">
          <app-search
            *ngIf="sharedService.CheckAuthorization(2902)"
            (SendFreeText)="ReciveFreeText($event)"
          >
          </app-search>
        </div>
        <div class="col-md-2 col-sm-4 px-2">
          <div
            class="input-group"
            *ngIf="sharedService.CheckAuthorization(2902)"
          >
            <select class="form-control" (change)="filterByStatus($event)">
              <option value="" disabled selected>
                {{ "stats" | translate }}
              </option>
              <option value="null">{{ "ShowSuspended" | translate }}</option>
              <option value="1">{{ "HideSuspended" | translate }}</option>
            </select>
          </div>
        </div>
   
        <div class="col-md-2 col-sm-4 px-2" *ngIf="!asModal">
          <app-import-export
            (exportExelFile)="exportProductsCategories()"
          ></app-import-export>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12" *ngIf="Categories; let Categories; else: loading">
    <div class="table-card">
      <table class="table table-stripedd table-sm table-bordered">
        <thead class="table-head">
          <tr>
            <th>{{ "Name" | translate }}</th>
            <th>{{ "Status" | translate }}</th>
            <th *ngIf="!asModal">{{ "Action" | translate }}</th>
            <th *ngIf="asModal">{{ "Add_Account" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Categories; let i = index">
            <td>
              {{ sharedService.Lang == "ar" ? item.NameAr : item.NameEn }}
            </td>
            <td>
              {{
                item.Status == 0
                  ? ("Suspended" | translate)
                  : ("Active" | translate)
              }}
            </td>
            <td *ngIf="!asModal">
              <button
                class="btn btn-success btn-sm my-0 px-3"
                (click)="ModalEdit($event, item)"
                data-toggle="modal"
                data-target="#ModalAddEdit"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                class="btn btn-danger btn-sm my-0 px-3"
                (click)="OpenModal($event, item)"
                data-toggle="modal"
                data-target="#Delete"
              >
                <i class="fas fa-trash"></i>
              </button>

              <!-- Modal Delete   -->
              <div
                class="modal fade"
                id="Delete"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                *ngIf="editState && itemToEdit.Id == item.Id"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5
                        class="modal-title text-capitalize"
                        id="exampleModalLabel"
                      >
                        {{ "Delete" | translate }}
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div class="text-center py-4">
                        <h4>{{ "DeleteConfirm" | translate }}?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        *ngIf="sharedService.CheckAuthorization(2901)"
                        type="button"
                        class="btn btn-success"
                        data-dismiss="modal"
                        (click)="Delete(item)"
                      >
                        {{ "Yes" | translate }}
                      </button>
                      <button
                        *ngIf="sharedService.CheckAuthorization(2903)"
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                      >
                        {{ "No" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td *ngIf="asModal">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text px-1"
                    id="basic-addon1"
                    *ngIf="modeType"
                    >{{ "IsDebit" | translate }}</span
                  >
                  <span
                    class="input-group-text px-1"
                    id="basic-addon1"
                    *ngIf="!modeType"
                    >{{ "IsCredit" | translate }}</span
                  >
                </div>
                <input
                  class="form-control"
                  type="text"
                  name="ChildAccountName"
                  [ngModel]="item?.accountSelected?.Name"
                />

                <div class="input-group-prepend">
                  <button
                    class="btn btn-sm mx-0 my-0 px-3 z-depth-0 waves-effect"
                    type="button"
                    id="button-addon1"
                    (click)="getAccountInfo2(item)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div class="input-group-prepend">
                  <button
                    class="btn btn-sm mx-0 my-0 px-3 z-depth-0 waves-effect"
                    type="button"
                    (click)="onRemoveAccount(item)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center py-4 my-4" *ngIf="Categories.length == 0">
        <h4 class="py-4">{{ "NoDataFound" | translate }}</h4>
        <br />
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <ng-template #loading>
      <div class="col-md-12 text-center py-4 my-4">
        <i class="fas fa-circle-notch fa-spin fa-3x"></i>
      </div>
    </ng-template>
  </div>

  <div class="col-md-12">
    <br />
    <app-pagination (GetSources)="GetData()" #pager></app-pagination>
  </div>
</div>
