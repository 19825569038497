import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AutoRegistrationComponent,
  AutoRegistrationRoutesConfig,
  BranchListComponent,
  ChooseBusinessComponent,
  ForgetPasswordComponent,
  LoginComponent,
  SignUpComponent,
  UpdatePasswordComponent,
  VerficationComponent,
  VerifyIndentityComponent,
} from 'auto-registration';
import { DoneSuccessfullyComponent } from 'projects/core/src/lib/components/done-successfully/done-successfully.component';
import { UserListComponent } from 'projects/auto-registration/src/lib/auto-registration/user-list/user-list.component';

import { branchesResolver } from 'projects/core/src/public-api';

export const registrationRoutesConfig: AutoRegistrationRoutesConfig = {
  LOGIN: 'login',
  SIGNUP: 'registration-cycle',
  OLD_SIGNUP: 'registration-cycle',
  RESET_PASSWORD: 'reset-password',
  UPDATE_PASSWORD: 'update-password',
  VERIFY: 'verify',
  VERIFY_IDENTITY: 'verify-identity',
  CHOOSE_BUSINESS: 'choose-business',
  BRANCH_LIST: 'branch-list',
  SUCCESS_REGISTERATION: 'success-registration',
  USER_LIST: 'user-list',
};

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: AutoRegistrationComponent,
        children: [
          { path: registrationRoutesConfig.LOGIN, component: LoginComponent },
          {
            path: registrationRoutesConfig.SIGNUP,
            component: SignUpComponent,
          },
          {
            path: registrationRoutesConfig.OLD_SIGNUP,
            component: SignUpComponent,
          },
          {
            path: registrationRoutesConfig.RESET_PASSWORD,
            component: ForgetPasswordComponent,
          },
          {
            path: registrationRoutesConfig.UPDATE_PASSWORD,
            component: UpdatePasswordComponent,
          },
          {
            path: registrationRoutesConfig.CHOOSE_BUSINESS,
            component: ChooseBusinessComponent,
          },
          {
            path: registrationRoutesConfig.VERIFY,
            component: VerficationComponent,
          },
          {
            path: registrationRoutesConfig.VERIFY_IDENTITY,
            component: VerifyIndentityComponent,
          },
          {
            path: registrationRoutesConfig.SUCCESS_REGISTERATION,
            component: DoneSuccessfullyComponent,
          },
          {
            path: registrationRoutesConfig.BRANCH_LIST,
            component: BranchListComponent,
            resolve: { branchListResponse: branchesResolver },
          },
          {
            path: registrationRoutesConfig.USER_LIST,
            component: UserListComponent,
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {}
