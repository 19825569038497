import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/other/shared.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-print-employee',
  templateUrl: './print-employee.component.html',
  styleUrls: ['./print-employee.component.scss']
})
export class PrintEmployeeComponent implements OnInit {


  @Input() public readonly item;
  @Input() public readonly AccountSetup;
  @Input() public dirc;
  public BillInfoUrl: string;
  public BillInfoForPackageUrl: string;

  public printLang: string = 'ar';

  constructor(
    public sharedService: SharedService,
    public translate: TranslateService,

  ) {

  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;

  ngOnInit(): void {

    this.BillInfoUrl = location.origin + "/transaction/bill-info/billItemId/";
    this.BillInfoForPackageUrl = location.origin + "/transaction/bill-info/billPackageItemId/";

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintEmployeeLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = "en";
      this.sharedService.SetTranslationPipeData();
    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = "ar";
      this.sharedService.SetTranslationPipeData();

    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'all') {
      console.log('all')

    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage && this.AccountSetup?.SelectedPrintEmployeeLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintEmployeeLanguage;
    }

  }
  hasNonNullEmployeeTransactionCommission(item: any): boolean {
    return item.TrProductServices.some(service => service.EmployeeTransactionCommission
      && service.EmployeeTransactionCommission.CommissionValue > 0);
  }


  getEmployeeName(employeeId: number): string {

    let employeeName = null;

    var getTransactionItem = this.item.
      TrProductServices.
      find(ti => ti.EmployeeId == employeeId);

    if (getTransactionItem) {

      employeeName = getTransactionItem.Employee ? (this.printLang == "ar" ?
        getTransactionItem.Employee?.NameAr :
        getTransactionItem.Employee?.NameEn) :
        this.translate.instant('NotFound');

    } else {

      var itemsWithPackages = this.item.TrProductServices.
        filter(tr => tr.TransactionsPackageServices &&
          tr.TransactionsPackageServices.length > 0);

      if (itemsWithPackages && itemsWithPackages.length > 0) {
        itemsWithPackages.forEach((itemWithPackages, index) => {

          var transactionsPackageServices = itemWithPackages.
            TransactionsPackageServices.
            find(ti => ti.EmployeeId == employeeId);

          if (transactionsPackageServices) {

            employeeName = transactionsPackageServices.Employee ? (this.printLang == "ar" ?
              transactionsPackageServices.Employee?.NameAr :
              transactionsPackageServices.Employee?.NameEn) :
              this.translate.instant('NotFound');
          }

        });

      }
    }

    return employeeName ?? this.translate.instant('NotFound');

  }

}
