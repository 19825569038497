import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ResponseState } from '../../models/support/response-state';
import { BaseServiceSZ } from 'src/app/services/other/baseService';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpBaseService {
  constructor(
    private sharedService: SharedService,
    private _BaseService: BaseServiceSZ,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  /*getHeaders(componentname :string) {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', "Bearer " + this.sharedService.Token)
        .set('DEVICETYPE','Branch')
        .set('AddedByDevice', AddedByDevice.GlameraBusinessWebInBranch + "")
        .set('Lang', this.sharedService.Lang)
        .set('IsMainBranch', this.sharedService.IsMainBranch ? "TRUE" : "FALSE")
       // .set('COMPONENTNAME',componentname)
        .set('Accept-Language', this.sharedService.Lang)
        .set("Access-Control-Allow-Headers", "*")
    }
  }*/
  getUploadHeaders() {
    return {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.sharedService.Token
      ),
    };
  }
  getHeadersWithoutAuthorization() {
    return {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
  }

  getHeadersXWWWFormURLEncoded() {
    return {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };
  }

  handleErrorResult(response: ExecutionResponse<any>) {
    if (response.State == ResponseState.ValidationError) {
      // tost message here
    } else if (response.State == ResponseState.Error) {
      // tost general message
      // log exception
    }
  }

  handleError<T>(): any {
    return (error: HttpErrorResponse): Observable<any> => {
      // tost error or handle it
      if(error.status == 401){
        this.LogOut();
      }
      return of({});
    };
  }

  LogOut() {
    var lang = !localStorage.getItem('lang')
      ? 'ar'
      : localStorage.getItem('lang');
    var dir = !localStorage.getItem('dir')
      ? 'rtl'
      : localStorage.getItem('dir');

    localStorage.clear();

    localStorage.setItem('lang', lang);
    localStorage.setItem('dir', dir);

    this.sharedService.TokenObject = null;
    location.href = '/login';
  }

  PostFile(endPoint: string, data: any, file: File = null, isNew:boolean=false): Observable<any> {
    let apiUrl = isNew? this.sharedService.NewApiUrl: this.sharedService.ApiUrl;
    const formData = new FormData();
    formData.append('Files', file);
    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(`${apiUrl}${endPoint}`, formData, this.sharedService.getHeaders('FORM'))
  }

  Post(endPoint: string, data: any, isNew:boolean=false): Observable<ExecutionResponse<any>> {
    let apiUrl = isNew? this.sharedService.NewApiUrl: this.sharedService.ApiUrl;
    /*let apiUrl = this.sharedService.UserId;
    let ff = this.sharedService.getHeaders();*/
    return this.httpClient
      .post<any>(`${apiUrl}${endPoint}`, data, this.sharedService.getHeaders())
      .pipe(
        tap((response) => {
          this.handleErrorResult(response);
        }),
        map((response) => response),
        catchError(this.handleError<any>())
      );
  }

  PostForm(
    endPoint: string,
    data: FormData
  ): Observable<ExecutionResponse<any>> {
    let apiUrl = this.sharedService.ApiUrl;
    /*let apصصiUrl = this.sharedService.UserId;
    let ff = this.sharedService.getHeaders();*/
    return this.httpClient
      .post<any>(`${apiUrl}${endPoint}`, data, {
        ...this.sharedService.getHeaders('FORM'),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        tap((response: any) => {
          this.handleErrorResult(response);
        }),
        map((response) => response),
        catchError(this.handleError<any>())
      );
  }

  Postv2(endPoint: string, data: any): Observable<ExecutionResponse<any>> {
    let apiUrl = this.sharedService.ApiUrlV2;
    let ff = this.sharedService.getHeaders();
    return this.httpClient
      .post<any>(`${apiUrl}${endPoint}`, data, this.sharedService.getHeaders())
      .pipe(
        tap((response) => {
          this.handleErrorResult(response);
        }),
        map((response) => response),
        catchError(this.handleError<any>())
      );
  }

  PostGeneric<T>(
    endPoint: string,
    data: any,
    componentname?: string
  ): Observable<T> {
    let apiUrl = this.sharedService.ApiUrl;
    return this.httpClient
      .post<any>(`${apiUrl}${endPoint}`, data, this.sharedService.getHeaders())
      .pipe(
        tap((response) => {
          this.handleErrorResult(response);
        }),
        map((response) => response),
        catchError(this.handleError<any>())
      );
  }

  // handleError(error: HttpErrorResponse){
  //   if(error.status == 422 || error.status ==500){
  //     var errorResponse:ExceptionResponse = error.error;
  //     this.sharedService.ToastrError(errorResponse.Message);
  //   }
  //   throw error;
  // }
  Get(endPoint: string, queryParams: any = {}, isNew:boolean=false): Observable<any> {
    let url = `${isNew? this.sharedService.NewApiUrl: this.sharedService.ApiUrl}${endPoint}`;
    return this.httpClient
      .get(url, {
        params: this.RemoveNull(queryParams),
        headers: this.sharedService.getHeaders().headers,
      })
      .pipe(
        tap((res) => {}),
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  private RemoveNull(queryParams: any) {
    Object.keys(queryParams).forEach((key) => {
      if (
        queryParams[key] == null ||
        queryParams[key] == undefined ||
        queryParams[key] == 'null' ||
        queryParams[key] == 'undefined'
      )
        delete queryParams[key];
    });
    return queryParams;
  }
}
