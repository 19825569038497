import { Transactions } from "./transactions";
import { PaymentTypes } from '../../enums/payment-types';
import { AccountCurrency } from '../account-setup/account-currency';
import { PaymentMethod } from "../cashier/payment-method";

export class TransactionsPayments {
  constructor() {
    this.Id = 0;
  }
  public Id: number;
  public TransactionId: number;
  public Amount: number;
  public PaymentTypeId: PaymentTypes;
  public PaymentTypeDesc: string;
  public Transaction: Transactions;
  public AccountSetupId: number;
  public CurrencyId: number;
  public Code: string;
  public AccountCurrency: AccountCurrency;
  public CurrencyFactor: number;
  public VisaTypeId?: number;
  public VisaNumber?: string;
  public PaymentMethodId: number;

  public PaymentMethod: PaymentMethod;
  public PaymentMethodName: string;
  public ReferenceCode: string;
  public GiftCardCode: string;
  public GiftCardInformationId: number;
  public GiftCardOTPCode: string;
  public GiftCardInformationOTPId: number;
  public CreditCardId: number;



}
