import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GymClientMembershipsSearchCriteria } from '../../models/search-criterias/gym-client-memberships-searchcriteria';
import { GymClientMembershipsSearchResult } from '../../models/search-result/gym-client-memberships-search-result';
import { GetGymClientMembershipClassesSearchCriteria } from '../../models/search-criterias/getGymClientMembershipClassesSearchCriteria';
import { GetGymClientMembershipClassesSearchResult } from '../../models/search-result/getGymClientMembershipClassesSearchResult';
import { DatePipe } from '@angular/common';
import { ChangeGymMembershipClientModel } from '../../models/search-criterias/change-gym-membership-client-model';
import { ExecutionResponse } from '../../models/support/execution-response';
import { CancelGymMembershipModel } from '../../models/search-criterias/cancel-gym-membership-model';


const datepipe: DatePipe = new DatePipe('en-US')

@Injectable({
  providedIn: 'root'
})




export class GymClientMembershipService extends BaseService {
  isAlterEgoTaken(alterEgo: string): Observable<boolean> {
    const isTaken = false; //alterEgo.includes(alterEgo);
    return of(isTaken).pipe(delay(400));
  }
  private clientGymMembershipUrl // = super.BaseUrl() + 'Client';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.clientGymMembershipUrl = this.sharedService.ApiUrl + 'GymClientMembership';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }


  GetGymClientMemberships(clientGymMembershipsSearchCriteria: GymClientMembershipsSearchCriteria): Observable<GymClientMembershipsSearchResult> {

    return this.http.post<GymClientMembershipsSearchResult>(this.clientGymMembershipUrl + '/Get', clientGymMembershipsSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Client Gym Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<GymClientMembershipsSearchResult>('Fetched Client Gym Memberships id=${id}'))
      );
  }


  ChangeGymMembershipClient(model: ChangeGymMembershipClientModel): Observable<ExecutionResponse<boolean>> {

    return this.http.post<ExecutionResponse<boolean>>(this.clientGymMembershipUrl + '/ChangeGymMembershipClient', model, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Change Gym Membership Client');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('Change Gym Membership Client'))
      );
  }

  CancelGymMembership(model: CancelGymMembershipModel): Observable<ExecutionResponse<boolean>> {

    return this.http.post<ExecutionResponse<boolean>>(this.clientGymMembershipUrl + '/CancelGymMembership', model, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Cancel Gym Membership');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('Cancel Gym Membership'))
      );
  }



  ExportGymMemberships(criteria: GymClientMembershipsSearchCriteria):
    Observable<any> {
    return this.http.get(
      this.clientGymMembershipUrl + '/GetExportedGymMemberships',
      {
        headers: this.sharedService.getHeaders('ExportExcel').headers,
        params: this.sharedService.ToHttpParams(criteria),
        responseType: 'blob'
      }
    )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetClientSales');
          //this._BaseService.ValidationResult(res);
        })
      );
  }


  public ExportGymMemberships_Old(criteria: GymClientMembershipsSearchCriteria) {


    let params = new HttpParams();
    params = params.append('AccountSetupId', this.sharedService.AccountSetupId);

    if (criteria.ClientIds && criteria.ClientIds.length > 0) {
      params = params.append('ClientIds', criteria.ClientIds ? criteria.ClientIds.join(', ') : null);
    }
    if (criteria.FreeText && criteria.FreeText.length > 0) {
      params = params.append('FreeText', criteria.FreeText);
    }
    if (criteria.MembershipStatus && criteria.MembershipStatus > 0) {
      params = params.append('MembershipStatus', criteria.MembershipStatus ? criteria.MembershipStatus : null);
    }

    return this.http.get(`${this.sharedService.ApiUrl}GymClientMembership/GetExportedGymMemberships`, {
      params: params,
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'Lang': this.sharedService.Lang,
        'Content-Type': 'application/octet-stream',
      }),
      responseType: 'blob'
    }).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );

  }



  GetGymClientMembershipClasses(GetgymClientMembershipClassesSearchCriteria: GetGymClientMembershipClassesSearchCriteria): Observable<GetGymClientMembershipClassesSearchResult> {

    return this.http.post<GetGymClientMembershipClassesSearchResult>(this.clientGymMembershipUrl + '/GetGymClientMembershipClasses', GetgymClientMembershipClassesSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Client Gym Memberships Classes');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<GetGymClientMembershipClassesSearchResult>('Fetched Client Gym Memberships Classes id=${id}'))
      );
  }


}
