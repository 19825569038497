import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../../services/other/shared.service';
import { BookingService } from 'src/app/dashboard/bookings/service/booking.service';
import { ResponseState } from '../../../models/support/response-state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-print-booking-bill',
  templateUrl: './print-booking-bill.component.html',
  styleUrls: ['./print-booking-bill.component.scss']
})
export class PrintBookingBillComponent implements OnInit {

  @Input() public readonly item;

  @Input() public readonly AccountSetup;


  constructor(
    public sharedService: SharedService,
    public activeModal: NgbActiveModal,
  
  ) { }

  ngOnInit(): void {
  
  
  
  }
 



}
