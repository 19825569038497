<div class="print-wrap">
  <div class="" style="width: 380; padding: 10px; margin: 0px auto">
    <div *ngIf="item" style="width: 100%;">
      <div id="print" class="printBox" style="background-color: #fff">

        <div style="
            text-align: start;
            direction: rtl;
            width: 100%;
            margin: 0;
            background-color: #fff;
          " [dir]="dirc">

          <h1 class="bordered-heading" style=" border: 1px solid #000;
          border-radius: 20px; border-top-right-radius: 0; border-bottom-left-radius: 0;
                    padding: 0;width: 100%;
                    text-align: center;
                  ">
            {{'BookingReminder' | translate}}
          </h1>

          <!-- <div class="row">
            <div class="col-lg-12 " style="text-align: center;">
              <p class="hed" style="
              margin: 0;
              padding: 0;
              width: 100%;
              text-align: center;
              color: #000;
              font-weight: 600;
              font-size: 30px; ">
                {{'BookingReminder' | translate}}
              </p>
            </div>
          </div> -->

          <hr />

          <table style="width: 100%;color:#000;font-size:22px;font-weight:900;" [dir]="dirc">
            <tbody style="color:#000;font-size:22px;font-weight:900;">
              <tr style="color:#000;font-size:22px;font-weight:900;">
                <td style="width: 25%;">{{'TheDay' | translate}}</td>
                <td>
                  <span *ngIf="item.WeekDay == 0">{{ 'Sunday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 1">{{ 'Monday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 2">{{ 'Tuesday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 3">{{ 'Wednesday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 4">{{ 'Thursday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 5">{{ 'Friday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 6">{{ 'Saturday' | translate }}</span>
                </td>
                <td *ngIf="lang == 'en'">
                  {{ item.CurrDate | date: 'dd/MM/yyyy' }}
                </td>
                <td *ngIf="lang == 'ar'">
                  {{ item.CurrDate | date: 'yyyy/MM/dd' | arabicDate }}
                </td>
              </tr>
              <tr style="color:#000;font-size:22px;font-weight:900;">
                <td style="width: 25%;">{{'Employee' | translate}}</td>
                <td>{{item.EmployeeName }}</td>
                <td>{{item.EmployeeId }}</td>
              </tr>
            </tbody>
          </table>

          <br>
          <br>
          <br>

          <div class="row">
            <table style="width:100%; ">
              <thead>
                <tr style="color:#000;font-size:22px;font-weight:900;">
                  <th>{{'Client' | translate}}</th>
                  <th>{{'Time' | translate}}</th>
                  <th>{{'Details' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of item.ServicesList" style="color:#000;font-size:22px;font-weight:900;">
                  <td>{{x.ClientName}}</td>
                  <td>
                    <span *ngIf="lang == 'ar'">{{x.ServiceDate | date: 'hh:mm ' | arabicDate }}</span>
                    <span *ngIf="lang == 'en'">{{x.ServiceDate | date: 'hh:mm ' }}</span>
                    {{x.ServiceDate | date: 'a' | translate}}
                  </td>
                  <td>
                    <div>{{x.ServiceName}}</div>
                    <div>{{'Count' | translate}} : {{x.Quantity}}</div>
                    <div>{{'BookingNumber' | translate}} : {{x.BookingId}}</div>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
