import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/other/shared.service';

@Component({
  selector: 'app-loyality-points',
  templateUrl: './loyality-points.component.html',
  styleUrls: ['./loyality-points.component.scss'],
})
export class LoyalityPointsComponent implements OnInit, OnChanges {
  constructor(
    public readonly sharedService: SharedService,
    private readonly fb: FormBuilder,
    public readonly translate: TranslateService
  ) {}

  loyalityPointsForm: FormGroup;
  @Input() getLoyalityForm: boolean;
  @Output() submittedForm = new EventEmitter<any>();
  @Input() formInput: any;
  numberOfExpired: { nameAr: string; nameEn: string; value: number }[] = [];
  showPointsSections: boolean = false;

  ngOnInit(): void {
    this.initForm();
    this.generateNumbersOfExpired();
    this.loyalityPointsForm
      .get('XPointsCount')
      .valueChanges.subscribe((res) => {
        this.updateValidity();
      });
  }

  initForm(): void {
    this.loyalityPointsForm = this.fb.group({
      PreventOnUsingPromo: this.fb.control(
        this.formInput?.PreventOnUsingPromo
      ),
      PreventOnUsingDiscount: this.fb.control(
        this.formInput?.PreventOnUsingDiscount
      ),
      ServicePointsAndDollarsAreGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.ServicePointsAndDollarsAreGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      ServiceLoyaltyPointsGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.ServiceLoyaltyPointsGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      ServiceLoyaltyPointsExpiryDays: this.fb.control(
        this.formInput?.ServiceLoyaltyPointsExpiryDays,
        [Validators.required]
      ),

      ProductPointsAndDollarsAreGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.ProductPointsAndDollarsAreGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      ProductLoyaltyPointsGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.ProductLoyaltyPointsGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      GiftCardPointsAndDollarsAreGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.GiftCardPointsAndDollarsAreGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      GiftCardLoyaltyPointsGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.GiftCardLoyaltyPointsGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      GiftCardLoyaltyPointsExpiryDays: this.fb.control(
        this.formInput?.GiftCardLoyaltyPointsExpiryDays,
        [Validators.required]
      ),
      PackagePointsAndDollarsAreGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.PackagePointsAndDollarsAreGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      PackageLoyaltyPointsGivenForEveryXDollarSpent: this.fb.control(
        this.formInput?.PackageLoyaltyPointsGivenForEveryXDollarSpent,
        [Validators.required]
      ),
      PackageLoyaltyPointsExpiryDays: this.fb.control(
        this.formInput?.PackageLoyaltyPointsExpiryDays,
        [Validators.required]
      ),
      ProductLoyaltyPointsExpiryDays: this.fb.control(
        this.formInput?.ProductLoyaltyPointsExpiryDays,
        [Validators.required]
      ),
      LoyaltyPointsMinPoints: this.fb.control(
        this.formInput?.LoyaltyPointsMinPoints,
        [Validators.required]
      ),
      XPointsCount: this.fb.control(this.formInput?.XPointsCount, [
        Validators.required,
      ]),
      XPointsValue: this.fb.control(this.formInput?.XPointsValue, [
        Validators.required,
      ]),
      AllowDynamicExchange: this.fb.control(
        !(this.formInput?.AllowDynamicExchange)
      ),
      PredefinedPromocodes: this.fb.array([]),
    });

    if (this.formInput?.PredefinedPromocodes?.length) {
      this.addEditControl(this.formInput?.PredefinedPromocodes);
    }
  }

  get myFormArray(): FormArray {
    return this.loyalityPointsForm.get('PredefinedPromocodes') as FormArray;
  }

  addControl(): void {
    const newControl = this.fb.group({
      NumberOfPoints: this.fb.control(null, [
        Validators.required,
        this.minValueValidator.bind(this),
      ]),
      Amount: this.fb.control(null, [Validators?.required]),
      ColorCode: this.fb.control(null, [Validators?.required]),
    });
    this.myFormArray.push(newControl);
  }

  addEditControl(data: any[]): void {
    data.forEach((item) => {
      const myObjControl = this.fb.group({
        NumberOfPoints: [
          item?.NumberOfPoints,
          [Validators.required, this.minValueValidator.bind(this)],
        ],
        Amount: [item?.Amount, [Validators.required]],
        ColorCode: [item?.ColorCode, [Validators.required]],
        Id: [item?.Id, null],
      });
      this.myFormArray.push(myObjControl);
    });
  }

  onRemoveControl(index: number): void {
    this.myFormArray.removeAt(index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.getLoyalityForm?.currentValue) {
      this.onSubmittedform();
    }
  }
  get isProductDisabled(): boolean {
    return !this.loyalityPointsForm.get('ProductLoyaltyPointsGivenForEveryXDollarSpent').value ||
    !this.loyalityPointsForm.get('ProductPointsAndDollarsAreGivenForEveryXDollarSpent').value;
  }
  get isServiceDisabled() : boolean{
    return !this.loyalityPointsForm.get('ServiceLoyaltyPointsGivenForEveryXDollarSpent').value ||
    !this.loyalityPointsForm.get('ServicePointsAndDollarsAreGivenForEveryXDollarSpent').value;
  }
  get isGiftCardDisabled() : boolean{
    return !this.loyalityPointsForm.get('GiftCardLoyaltyPointsGivenForEveryXDollarSpent').value ||
    !this.loyalityPointsForm.get('GiftCardPointsAndDollarsAreGivenForEveryXDollarSpent').value;
  }
  get isPackageDisabled() : boolean{
    return !this.loyalityPointsForm.get('PackageLoyaltyPointsGivenForEveryXDollarSpent').value ||
    !this.loyalityPointsForm.get('PackagePointsAndDollarsAreGivenForEveryXDollarSpent').value;
  }

  minValueValidator(control: AbstractControl): { [key: string]: any } | null {
    const inputValue = control.value;
    const min = this.loyalityPointsForm.get('XPointsCount')?.value;

    if (inputValue < min) {
      return { minValue: { min } };
    }

    return null;
  }

  onChangeAllowDynamicExchange(): void {
    if (!this.loyalityPointsForm.get('AllowDynamicExchange').value) {
      this.myFormArray.clear();
    } else {
      this.addControl();
    }
  }

  onChangeNumberOfPoints(event: any, index: number): void {
    const formArr = this.loyalityPointsForm.get(
      'PredefinedPromocodes'
    ) as FormArray;
    for (let i = 0; i < formArr.value?.length; i++) {
      formArr.at(i).get('NumberOfPoints').updateValueAndValidity();
    }

    const myNumber = typeof event === 'number' ? event : event?.target?.value;

    const XPointsCount = this.loyalityPointsForm.get('XPointsCount')?.value;
    const XPointsValue = this.loyalityPointsForm.get('XPointsValue')?.value;

    if (myNumber >= XPointsCount) {
      const pointValue = (myNumber / XPointsCount) * XPointsValue;
      formArr.at(index).get('Amount').setValue(pointValue);
    }
  }

  onCalculateThePoints(): void {
    const formArr = this.loyalityPointsForm.get(
      'PredefinedPromocodes'
    ) as FormArray;

    const XPointsCount = this.loyalityPointsForm.get('XPointsCount')?.value;
    const XPointsValue = this.loyalityPointsForm.get('XPointsValue')?.value;
    for (let i = 0; i < formArr.value?.length; i++) {
      formArr.at(i).get('NumberOfPoints').updateValueAndValidity();
      if (formArr.at(i).get('NumberOfPoints')?.value >= XPointsCount) {
        const pointValue =
          (formArr.at(i).get('NumberOfPoints')?.value / XPointsCount) *
          XPointsValue;
        formArr.at(i).get('Amount').setValue(pointValue);
      }
    }
  }

  generateNumbersOfExpired(): void {
    for (let i = 0; i < 60; i++) {
      this.numberOfExpired.push({
        nameAr: i + 1 + ' يوم',
        nameEn: i + 1 + ' Day',
        value: i + 1,
      });
    }
  }

  updateValidity(): void {
    const formArr = this.loyalityPointsForm.get(
      'PredefinedPromocodes'
    ) as FormArray;

    const numberOfArr = formArr.value?.length;

    for (let i = 0; i < numberOfArr; i++) {
      formArr.at(i).get('NumberOfPoints').updateValueAndValidity();
      this.onChangeNumberOfPoints(
        formArr.at(i).get('NumberOfPoints')?.value,
        i
      );
    }
  }
  onSubmittedform(): void {
    this.submittedForm.emit({
      ...this.loyalityPointsForm.value,
      AllowDynamicExchange: !this.loyalityPointsForm.get('PredefinedPromocodes')
        ?.value?.length,
    });
  }
}
