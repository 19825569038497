import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AutoRegistrationService,
  BRANCH,
  ComponentBase,
  GET_GENERAL_TOKEN_INPUT,
  PAGING_PARAMS,
  SharedService,
} from 'core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { SupportChatService } from 'projects/core/src/lib/services/support-chat.service';
import { takeUntil } from 'rxjs/operators';
import { BaseServiceSZ } from 'src/app/services/other/baseService';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss'],
})
export class BranchListComponent extends ComponentBase implements OnInit {
  branchList: BRANCH[] = [];
  totalCount!: number;
  branchId: number;
  branchUser: BRANCH[] = [];
  searchText: string = '';
  public isRtl: boolean = false;

  constructor(
    private supportChatService: SupportChatService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly datepipe: DatePipe,
    private readonly toastr: ToastrService,
    private readonly _baseService: BaseServiceSZ,
    private readonly autoRegistrationService: AutoRegistrationService,
    private readonly sharedService: SharedService,
    private readonly cookieService: CookieService,
    translateService: TranslateService
  ) {
    super();
    this.isRtl = translateService.currentLang === 'ar';
  }

  ngOnInit(): void {
    this.supportChatService.chatScript();
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.branchList = res['branchListResponse'].Result;
      this.totalCount = res['branchListResponse'].TotalCount;
    });

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.isSzSupport = params['isSzSupport'] === 'true' ? true : false;
      });
  }

  handlePageEvent(event: PageEvent): void {
    this.getBranchesList({
      PageSize: event.pageSize,
      PageNumber: event.pageIndex,
      FreeText: this.searchText,
    });
  }

  onSearchBranchList(event: any): void {
    this.searchText = event.target.value;
    if (this.searchText) {
      this.getBranchesList({
        PageNumber: 0,
        PageSize: 10,
        FreeText: this.searchText,
      });
    } else {
      this.getBranchesList({
        PageNumber: 0,
        PageSize: 10,
      });
    }
  }

  onClickBranchItem(event: number): void {
    this.branchId = event;
    if (this.isSzSupport) {
      this.router.navigate(['./user-list'], {
        queryParams: { branchId: this.branchId },
      });
    } else {
      this.getGeneralToken({ BranchId: event });
    }
  }

  onCategoryChange(event: any): void {
    this.getGeneralToken({ BranchId: this.branchId, BranchUserId: event.Id });
  }

  private getBranchesList(params: PAGING_PARAMS): void {
    this.autoRegistrationService
      .getBranches(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.branchList = res.Result;
        this.totalCount = res.TotalCount!;
      });
  }

  private getGeneralToken(data: GET_GENERAL_TOKEN_INPUT): void {
    this.autoRegistrationService
      .getGeneralToken(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (this.sharedService.checkResposeStatus(res.Message, res.State)) {
          this.sharedService.clearLocalStorage('transType');
          this.sharedService.clearLocalStorage('transType');
          this.sharedService.clearLocalStorage('stockTransDetaileds');
          this.sharedService.clearLocalStorage('tokenObject');
          this.sharedService.clearLocalStorage('tokenKey');
          this.sharedService.clearLocalStorage('UserIdentity');

          this.sharedService.setLocalStorage(
            'tokenObject',
            JSON.stringify(res.Result)
          );
          localStorage.setItem('UserIdentity', JSON.stringify(res.Result.User));
          localStorage.setItem('tokenKey', JSON.stringify(res.Result));

          debugger;
          // share token with all subdomains 
          //  document.cookie = `jwt_token=${res.Result.Token}; domain=.${environment.RootDomain}; path=/`;
          var user = res.Result.User;
          var SharedObject = {
            token: res.Result.Token,
            company:{id: res.Result.CompanyId, nameAr : res.Result.CompanyNameAr ,nameEn : res.Result.CompanyNameEn},
            user: { firstName: user.FirstName, lastName: user.LastName ,image :user.UserPicturePath }
          }
          this.cookieService.set('tokenObject', JSON.stringify(SharedObject), 1, '/', `.${environment.RootDomain}`);

          this.router.navigate(['./branch-list'], {
            queryParams: { isSzSupport: res.Result.IsSzSupport },
          });



          this.router.navigate(['/dashboard']);

          this.HandleAutomaticDayOpened(
            res.Result.AutomaticDayOpened,
            res.Result.UserFullName
          );
          this.HandleAutomaticShiftOpened(
            res.Result.AutomaticShiftOpened,
            res.Result.UserFullName,
            res.Result.AutomaticShiftOpenedChargeBeginingShift,
            res.Result.AutomaticShiftOpenedSafeGlobalName
          );
        }
      });
  }
  HandleAutomaticDayOpened(AutomaticDayOpened: boolean, UserFullName: string) {
    var thisVar = this;

    if (AutomaticDayOpened) {

      let currentDateFormated = this.datepipe.
        transform(new Date(), 'yyyy-MM-dd');

      thisVar._baseService
        .getTranslation('AutomaticDayOpened')
        .subscribe((a) => {
          thisVar._baseService.getTranslation('WithDate').subscribe((d) => {
            thisVar._baseService
              .getTranslation('Notifications')
              .subscribe((n) => {
                thisVar._baseService
                  .getTranslation('ForCashier')
                  .subscribe((c) => {
                    thisVar.toastr.info(
                      a +
                      ' - ' +
                      d +
                      ' ' +
                      currentDateFormated +
                      ' - ' +
                      c +
                      ' ' +
                      UserFullName +
                      '.',
                      n,
                      { timeOut: 50000 }
                    );
                  });
              });
          });
        });
    }
  }
  HandleAutomaticShiftOpened(
    AutomaticShiftOpened: boolean,
    UserFullName: string,
    AutomaticShiftOpenedChargeBeginingShift: number,
    AutomaticShiftOpenedSafeGlobalName: string
  ) {
    var thisVar = this;

    if (AutomaticShiftOpened) {
      thisVar._baseService
        .getTranslation('AutomaticShiftOpened')
        .subscribe((a) => {
          thisVar._baseService
            .getTranslation('ChargeBeginningShift')
            .subscribe((d) => {
              thisVar._baseService
                .getTranslation('Notifications')
                .subscribe((n) => {
                  thisVar._baseService
                    .getTranslation('ForCashier')
                    .subscribe((c) => {
                      thisVar._baseService
                        .getTranslation('ForSafe')
                        .subscribe((s) => {
                          thisVar.toastr.info(
                            a +
                            ' - ' +
                            d +
                            ' ' +
                            AutomaticShiftOpenedChargeBeginingShift +
                            ' - ' +
                            c +
                            ' ' +
                            UserFullName +
                            ' - ' +
                            s +
                            ' ' +
                            AutomaticShiftOpenedSafeGlobalName +
                            '.',
                            n,
                            { timeOut: 50000 }
                          );
                        });
                    });
                });
            });
        });
    }
  }
}
