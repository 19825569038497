import { Component, OnInit } from '@angular/core';
import {
  AutoRegistrationService,
  ComponentBase,
  USER,
  USER_BRANCHS_PARAMS,
  SharedService,
} from 'core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { SupportChatService } from 'projects/core/src/lib/services/support-chat.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends ComponentBase implements OnInit {
  userList: USER[] = [];
  branchId: number;
  totalCount!: number;
  queryData: USER_BRANCHS_PARAMS = {
    PageNumber: 0,
    PageSize: 10,
    branchId: undefined,
  };

  constructor(
    private supportChatService: SupportChatService,
    private readonly route: ActivatedRoute,
    private readonly autoRegistrationService: AutoRegistrationService,
    private readonly router: Router,
    public readonly sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.supportChatService.chatScript();
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        console.log(res);
        this.queryData.branchId = res?.branchId;
        if (this.queryData.branchId) {
          this.getUserBranch(this.queryData);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onClickUserItem(event: any): void {

    this.autoRegistrationService
      .getGeneralToken({
        BranchId: this.queryData.branchId,
        BranchUserId: event,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.checkResponse = this.sharedService.checkResposeStatus(
          res.Message,
          res.State
        );
        if (this.checkResponse) {
          this.sharedService.clearLocalStorage('transType');
          this.sharedService.clearLocalStorage('transType');
          this.sharedService.clearLocalStorage('stockTransDetaileds');
          this.sharedService.clearLocalStorage('tokenObject');
          this.sharedService.clearLocalStorage('tokenKey');
          this.sharedService.clearLocalStorage('UserIdentity');

          this.sharedService.setLocalStorage(
            'tokenObject',
            JSON.stringify(res.Result)
          );
          localStorage.setItem('UserIdentity', JSON.stringify(res.Result.User));
          localStorage.setItem('tokenKey', JSON.stringify(res.Result));
          this.router.navigate(['/dashboard']);
        }
      });
  }

  handlePageEvent(event: PageEvent): void {
    this.getUserBranch({
      ...this.queryData,
      PageNumber: event.pageIndex,
      PageSize: event.pageSize,
    });
  }

  onSearchBranchList(event: any): void {
    const searchText = event.target.value;
    if (searchText) {
      this.getUserBranch({ ...this.queryData, FreeText: searchText });
    } else {
      this.getUserBranch({ ...this.queryData });
    }
  }

  private getUserBranch(params: USER_BRANCHS_PARAMS) {
    this.autoRegistrationService.getUserBranches(params).subscribe((res) => { 
      this.userList = res.Result;
      this.totalCount = res.TotalCount;
    });
  }
}
