import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ExecutionResponse } from '../../../models/support/execution-response';
import { HttpBaseService } from '../../../services/other/httpbase.service';
import { SharedService } from '../../../services/other/shared.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    private httpBaseService: HttpBaseService,
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  /*=================================================================*/
  // GetBookings(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('Booking/Get', body)
  // }
  GetBookings(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/Get', body);
  }
  GetBookingsView(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/GetBookingsView', body);
  }


  GetBookingsForDayView(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/GetBookingsForDayView', body);
  }
  GetCalendarList(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/GetCalendarList', body);
  }
  getByIdBookings(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/getById', body);
  }
  GlameraCreateWithPayment(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/GlameraCreateWithPayment', body);
  }
  UpdateBooking(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/Update', body);
  }
  UpdateGlameraPayment(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GlameraPayment', body);
  }
  GetRoster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Roster/Get', body);
  }
  GetByIdAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/getById', body);
  }
  /*=================================================================*/
  GetEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Get', body);
  }
  GetAccountsWithService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Company/GetAccountsWithService', body);
  }
  /*=================================================================*/
  GetDropdowns(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDropdowns', body);
  }

  /*=================================================================*/
  // GetClassificationDropdown(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('SystemCodes/GetClassificationDropdown', body);
  // }
  GetClassificationDropdown(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDropdown', body);
  }

  /*=================================================================*/
  GetPackages(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OffersSetting/GetPackages', body);
  }
  GetPackageById(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OffersSetting/GetPackageById', body);
  }

  /*================ Equipment Or Room =======================================*/
  GetEquipmentOrRoom(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EquipmentOrRoom/Get', body);
  }
  CreateEquipmentOrRoom(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EquipmentOrRoom/Create', body);
  }
  UpdateEquipmentOrRoom(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EquipmentOrRoom/Update', body);
  }
  DeleteEquipmentOrRoom(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EquipmentOrRoom/delete', body);
  }
  /*================ Category =================================================*/
  GetCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/Get', body);
  }
  CreateCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/Create', body);
  }
  UpdateCategory(body, file: File): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.PostFile('v2/Category/Update', body, file);
  }
  DeleteCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/delete', body);
  }
  /*=================================================================*/
  GlameraServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/GlameraServices', body);
  }

  /*====================   Company Services   ==================================*/
  GetCompanyServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('companyServices/Get', body);
  }
  CreateCompanyServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('companyServices/Create', body);
  }
  UpdateCompanyServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('companyServices/Update', body);
  }
  /*====================   Services   ==================================*/
  GetService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/Get', body);
  }

  CreateService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/Create', body, true);
  }

  UpdateService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/Update', body, true);
  }

  DeleteService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/Delete', body);
  }
  /*====================   Comapny Categories   ==================================*/
  GetComapnyCategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('comapnyCategories/Get', body);
  }
  CreateComapnyCategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('comapnyCategories/Create', body);
  }
  UpdateComapnyCategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('comapnyCategories/Update', body);
  }

  /*=================================================================*/
  GetRetailProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/Get', body);
  }

  GetResourceManagment(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ResourceManagment/Get', body);
  }
  CreateResourceManagment(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ResourceManagment/Create', body);
  }
  GetDetailCodes(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDetailCodes', body);
  }

  GetEmployeeTimeSlots(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'v2/BookingService/GetEmployeeTimeSlots',
      body
    );
  }
  GetTodayBranchWorkingHours(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'v2/BookingService/GetTodayBranchWorkingHours',
      body
    );
  }
  GetEmployeeStatuses(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'BookingService/GetEmployeeStatuses',
      body
    );
  }
  /*=================================================================*/
  UpdateBookingStatus(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/UpdateBookingStatus', body);
  }
  /*=================================================================*/
  UpdateServiceBranches(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/UpdateServiceBranches', body);
  }
  /*=================================================================*/
  DealingWithBookingValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'DealingWithBookingValidation/DealingWithBookingValidation',
      body
    );
  }
  /*=================================================================*/
  UpdateServicesAndServiceSessionsIsWorkDone(
    body
  ): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'BookingService/UpdateServicesAndServiceSessionsIsWorkDone',
      body
    );
  }
  /*=================================================================*/
  GetAccountingReportForSessionsAndPulses(
    body
  ): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'ClientServiceSession/GetAccountingReportForSessionsAndPulses',
      body
    );
  }
  /*=================================================================*/
  GetClientBooking(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/GetClientBooking', body);
  }
  /*=================================================================*/
  SendManualSms(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/SendManualSms', body);
  }
  /*========================================================================================*/
  PostImageFile(body) {
    return this.http.post(
      `${this.sharedService.ApiUrl}Upload/PostImageFile`,
      body
    );
  }
  /*=================================================================*/

  public ExportServiceCategories() {
    return this.http
      .get(
        `${this.sharedService.ApiUrl}v2/Categories/ExportServiceCategories?AccountSetupId=${this.sharedService.AccountSetupId}`,
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.sharedService.Token,
            'Content-Type': 'application/octet-stream',
          }),
          responseType: 'blob',
        }
      )
      .pipe(
        tap(
          (data) => console.log('You received data'),
          (error) => console.log(error)
        )
      );
  }

  GetBookingPerEmployee(BranchId, currDate): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Get('v2/BookingService/GetBookingPerEmployee/' + BranchId + '/' + currDate);
  }
  /*=================================================================*/
  public ExportServices() {
    return this.http
      .get(
        `${this.sharedService.ApiUrl}v2/Services/Export?AccountSetupId=${this.sharedService.AccountSetupId}`,
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.sharedService.Token,
            'Content-Type': 'application/octet-stream',
          }),
          responseType: 'blob',
        }
      )
      .pipe(
        tap(
          (data) => console.log('You received data'),
          (error) => console.log(error)
        )
      );
  }

  // public ExportServices(body) {
  //   return this.http.post(`${this.sharedService.ApiUrl}/Services/Export`, body, {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Bearer ' + this.sharedService.Token,
  //       'Content-Type': 'application/octet-stream',
  //     }), responseType: 'blob',
  //   }).pipe(
  //     tap(
  //       data => console.log('You received data'),
  //       error => console.log(error)
  //     )
  //   );
  // }
}
